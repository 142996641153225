// @import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "assets/default-theme.css";

* {
    padding: 0;
    margin: 0;
    box-sizing: content-box;
}

html body {
    font-size: 10pt;
    line-height: normal;
    @media screen and (max-width: 760px) {
        font-size: 16px;
        line-height: 24px;
    }
    background-color: #FFFFFF;
    // font-family: Arial, Helvetica, sans-serif;
    font-family: Bahnschrift;
    color: #444444;
    -moz-text-size-adjust: auto;
    -webkit-text-size-adjust: auto;
    -ms-text-size-adjust: auto;

    height: 100vh;
    margin: 0;
}

p {
    line-height: 1.5em;
    padding-bottom: 5px;
    margin-bottom: 0;
    @media screen and (max-width: 760px) {
        margin-bottom: 1rem;
    }
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

img {
    vertical-align: baseline;
}

ol, ul {
    margin: 0;
}

table {
    border-spacing: 2px;
    border-collapse: separate;
    border-color: gray;
}

h1 h2 h3 h4 {
    font-weight: 600;
    @media screen and (max-width: 760px) {
        font-weight: 500;
        margin-bottom: .5rem;
        line-height: 1.2;
        margin-top: 0;
    }
    -moz-text-size-adjust: auto;
}

h1 {
    font-size: 16px;
    color: #CC0000;
    letter-spacing: 1px;
    padding-bottom: 3px;
}

h2 {
    font-size: 14px;
    @media screen and (min-width: 761px) {
        color: #444444;
    }
    @media screen and (max-width: 760px) {
        font-size: 2rem;
    }
    padding-bottom: 5px;
}

h3 {
    font-size: 12px;
    color: #AAAAAA;
    padding-bottom: 5px;
}

h4 {
    font-size: 10px;
    color: #DDDDDD;
    padding-bottom: 3px;
}

.bs-datepicker-head {
    background-color: #CC0000 !important;
}

.ng-marquee {
    background-color: red;
    height: 0px;
}

.ng-marquee>span {
    margin-top: 5px;
    font-size: 15pt;
    // animation-duration: 30s;
    // -webkit-animation-duration: 30s;
    // transform: translateX(-50%);
    // transform: translate3d(-100%, 0, 0);
    // -webkit-transform: translate3d(-100%, 0, 0);
    // transition: transform 100ms;
    // -webkit-transition: tranform 100ms;
    // left: 0;

    // &::before {
    //     will-change: transform !important;
    // }

    // -webkit-animation: moveSlideshow 165s  linear infinite;
    // -webkit-animation: moveSlideshow 30s steps(10000) infinite;
}

@keyframes movement-smooth {
    from {
        transform: translateX(0%);
        left: 100%;
    }
    to {
        transform: translateX(-100%);
        left: 0%;
    }
}

@-webkit-keyframes movement-steps20 {
    0% {
        transform: translateX(0%);
        left: 100%;
    }
    5%, 9.99% {
        transform: translateX(-5%);
        left: 95%;
    }
    10%, 14.99% {
        transform: translateX(-10%);
        left: 90%;
    }
    15%, 19.99% {
        transform: translateX(-15%);
        left: 85%;
    }
    20%, 24.99% {
        transform: translateX(-20%);
        left: 80%;
    }
    25%, 29.99% {
        transform: translateX(-25%);
        left: 75%;
    }
    30%, 34.99% {
        transform: translateX(-30%);
        left: 70%;
    }
    35%, 39.99% {
        transform: translateX(-35%);
        left: 65%;
    }
    40%, 44.99% {
        transform: translateX(-40%);
        left: 60%;
    }
    45%, 49.99% {
        transform: translateX(-45%);
        left: 55%;
    }
    50%, 54.99% {
        transform: translateX(-50%);
        left: 50%;
    }
    55%, 59.99% {
        transform: translateX(-55%);
        left: 45%;
    }
    60%, 64.99% {
        transform: translateX(-60%);
        left: 40%;
    }
    65%, 69.99% {
        transform: translateX(-65%);
        left: 35%;
    }
    70%, 74.99% {
        transform: translateX(-70%);
        left: 30%;
    }
    75%, 79.99% {
        transform: translateX(-75%);
        left: 25%;
    }
    80%, 84.99% {
        transform: translateX(-80%);
        left: 20%;
    }
    85%, 89.99% {
        transform: translateX(-85%);
        left: 15%;
    }
    90%, 94.99% {
        transform: translateX(-90%);
        left: 10%;
    }
    95%, 99.99% {
        transform: translateX(-95%);
        left: 5%;
    }
    100% {
        transform: translateX(-100%);
        left: 0%;
    }
}

@-webkit-keyframes movement-steps10 {
    0% {
        transform: translateX(0%);
        left: 100%;
    }
    10%, 19.99% {
        transform: translateX(-10%);
        left: 90%;
    }
    20%, 29.99% {
        transform: translateX(-20%);
        left: 80%;
    }
    30%, 39.99% {
        transform: translateX(-30%);
        left: 70%;
    }
    40%, 49.99% {
        transform: translateX(-40%);
        left: 60%;
    }
    50%, 59.99% {
        transform: translateX(-50%);
        left: 50%;
    }
    60%, 69.99% {
        transform: translateX(-60%);
        left: 40%;
    }
    70%, 79.99% {
        transform: translateX(-70%);
        left: 30%;
    }
    80%, 89.99% {
        transform: translateX(-80%);
        left: 20%;
    }
    90%, 99.99% {
        transform: translateX(-90%);
        left: 10%;
    }
    100% {
        transform: translateX(-100%);
        left: 0%;
    }
}

@-webkit-keyframes movement-steps5 {
    0%, 19.99% {
        transform: translateX(0%);
        left: 100%;
    }
    20%, 39.99% {
        transform: translateX(-20%);
        left: 80%;
    }
    40%, 59.99% {
        transform: translateX(-40%);
        left: 60%;
    }
    60%, 79.99% {
        transform: translateX(-60%);
        left: 40%;
    }
    80%, 99.99% {
        transform: translateX(-80%);
        left: 20%;
    }
    100% {
        transform: translateX(-100%);
        left: 0%;
    }
}
mark {
    color: white;
    background-color: red;
    margin: auto;
}

.mat-button-focus-overlay {
    display: none;
}
