/************** OVERRIDES **************/
.mdc-text-field {
    padding: 0 !important;
}

/* .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px
} */

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 15px;
    padding-bottom: 8px
}

.mat-mdc-form-field-infix {
    min-height: 48px
}

.mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: white;
}

.mat-mdc-row:nth-child(odd) {
    background-color: #ffffff;
}
.mat-mdc-row:nth-child(even) {
    background-color: #eeeeee;
}

.mat-mdc-dialog-container {
    --mdc-dialog-subhead-font: Bahnschrift;
    --mdc-dialog-subhead-line-height: 32px;
    --mdc-dialog-subhead-size: 20px;
    --mdc-dialog-subhead-weight: 500;
    --mdc-dialog-subhead-tracking: 0.0125em;
    --mdc-dialog-supporting-text-font: Bahnschrift;
    --mdc-dialog-supporting-text-line-height: 24px;
    --mdc-dialog-supporting-text-size: 16px;
    --mdc-dialog-supporting-text-weight: 400;
    --mdc-dialog-supporting-text-tracking: 0.03125em
}

html {
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-selected-checkmark-color: white;
    --mdc-checkbox-selected-focus-icon-color: black;
    --mdc-checkbox-selected-hover-icon-color: black;
    --mdc-checkbox-selected-icon-color: black;
    /* --mdc-checkbox-selected-pressed-icon-color: #ff4081; */
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    /* --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
    --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
    --mdc-checkbox-selected-pressed-state-layer-color: #ff4081; */
    --mdc-checkbox-unselected-focus-state-layer-color: black;
    --mdc-checkbox-unselected-hover-state-layer-color: black;
    --mdc-checkbox-unselected-pressed-state-layer-color: black
}

html {
    --mdc-filled-text-field-label-text-font: Bahnschrift;
    --mdc-filled-text-field-label-text-size: 12px;
    --mdc-filled-text-field-label-text-tracking: 0.03125em;
    --mdc-filled-text-field-label-text-weight: 400;
    --mdc-outlined-text-field-label-text-font: Bahnschrift;
    --mdc-outlined-text-field-label-text-size: 12px;
    --mdc-outlined-text-field-label-text-tracking: 0.03125em;
    --mdc-outlined-text-field-label-text-weight: 400;
    --mat-form-field-container-text-font: Bahnschrift;
    --mat-form-field-container-text-line-height: 24px;
    --mat-form-field-container-text-size: 12px;
    --mat-form-field-container-text-tracking: 0.03125em;
    --mat-form-field-container-text-weight: 400;
    --mat-form-field-outlined-label-text-populated-size: 16px;
    --mat-form-field-subscript-text-font: Bahnschrift;
    --mat-form-field-subscript-text-line-height: 20px;
    --mat-form-field-subscript-text-size: 12px;
    --mat-form-field-subscript-text-tracking: 0.0333333333em;
    --mat-form-field-subscript-text-weight: 400
}

html {
    --mat-table-header-container-height: normal;
    --mat-table-footer-container-height: 52px;
    --mat-table-row-item-container-height: 52px

    --mat-table-header-headline-size: 12px;
    --mat-table-header-headline-weight: 700;

}

html {
    --mat-menu-item-label-text-font: Bahnschrift;
    --mat-menu-item-label-text-size: 12px;
    --mat-menu-item-label-text-tracking: 0.03125em;
    --mat-menu-item-label-text-line-height: 20px;
    --mat-menu-item-label-text-weight: 400
}

/***************************************/


.mat-ripple {
    overflow: hidden;
    position: relative
}

    .mat-ripple:not(:empty) {
        transform: translateZ(0)
    }

    .mat-ripple.mat-ripple-unbounded {
        overflow: visible
    }

.mat-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity,transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale3d(0, 0, 0)
}

.cdk-high-contrast-active .mat-ripple-element {
    display: none
}

.cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    left: 0
}

[dir=rtl] .cdk-visually-hidden {
    left: auto;
    right: 0
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000
}

    .cdk-overlay-container:empty {
        display: none
    }

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%
}

.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0
}

    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 1
    }

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: .6
}

.cdk-overlay-dark-backdrop {
    background: rgba(0,0,0,.32)
}

.cdk-overlay-transparent-backdrop {
    transition: visibility 1ms linear,opacity 1ms linear;
    visibility: hidden;
    opacity: 1
}

    .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0;
        visibility: visible
    }

.cdk-overlay-backdrop-noop-animation {
    transition: none
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px
}

.cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll
}

textarea.cdk-textarea-autosize {
    resize: none
}

textarea.cdk-textarea-autosize-measuring {
    padding: 2px 0 !important;
    box-sizing: content-box !important;
    height: auto !important;
    overflow: hidden !important
}

textarea.cdk-textarea-autosize-measuring-firefox {
    padding: 2px 0 !important;
    box-sizing: content-box !important;
    height: 0 !important
}

@keyframes cdk-text-field-autofill-start { /*!*/
}

@keyframes cdk-text-field-autofill-end { /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
    animation: cdk-text-field-autofill-start 0s 1ms
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    animation: cdk-text-field-autofill-end 0s 1ms
}

.mat-focus-indicator {
    position: relative
}

    .mat-focus-indicator::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        box-sizing: border-box;
        pointer-events: none;
        display: var(--mat-focus-indicator-display, none);
        border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
        border-radius: var(--mat-focus-indicator-border-radius, 4px)
    }

    .mat-focus-indicator:focus::before {
        content: ""
    }

.cdk-high-contrast-active {
    --mat-focus-indicator-display: block
}

.mat-mdc-focus-indicator {
    position: relative
}

    .mat-mdc-focus-indicator::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        box-sizing: border-box;
        pointer-events: none;
        display: var(--mat-mdc-focus-indicator-display, none);
        border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
        border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px)
    }

    .mat-mdc-focus-indicator:focus::before {
        content: ""
    }

.cdk-high-contrast-active {
    --mat-mdc-focus-indicator-display: block
}

.mat-ripple-element {
    background-color: rgba(0,0,0,.1)
}

html {
    --mat-option-selected-state-label-text-color: #3f51b5;
    --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
    --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
    --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
    --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04)
}

.mat-accent {
    --mat-option-selected-state-label-text-color: #ff4081
}

.mat-warn {
    --mat-option-selected-state-label-text-color: #f44336
}

html {
    --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87)
}

.mat-pseudo-checkbox-full {
    color: rgba(0,0,0,.54)
}

    .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
        color: #b0b0b0
    }

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #3f51b5
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: #3f51b5
}

    .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
        color: #fafafa
    }

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #ff4081
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: #ff4081
}

    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
        color: #fafafa
    }

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #ff4081
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: #ff4081
}

    .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after, .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
        color: #fafafa
    }

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #f44336
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: #f44336
}

    .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after, .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
        color: #fafafa
    }

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #b0b0b0
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: #b0b0b0
}

.mat-app-background {
    background-color: #fafafa;
    color: rgba(0,0,0,.87)
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
    box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
    box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
    box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
    box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)
}

.mat-theme-loaded-marker {
    display: none
}

html {
    --mat-option-label-text-font: Bahnschrift;
    --mat-option-label-text-line-height: 24px;
    --mat-option-label-text-size: 16px;
    --mat-option-label-text-tracking: 0.03125em;
    --mat-option-label-text-weight: 400
}

html {
    --mat-optgroup-label-text-font: Bahnschrift;
    --mat-optgroup-label-text-line-height: 24px;
    --mat-optgroup-label-text-size: 16px;
    --mat-optgroup-label-text-tracking: 0.03125em;
    --mat-optgroup-label-text-weight: 400
}

.mat-mdc-card {
    --mdc-elevated-card-container-color: white;
    --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-outlined-card-container-color: white;
    --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
    --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54)
}

.mat-mdc-card {
    --mat-card-title-text-font: Bahnschrift;
    --mat-card-title-text-line-height: 32px;
    --mat-card-title-text-size: 20px;
    --mat-card-title-text-tracking: 0.0125em;
    --mat-card-title-text-weight: 500;
    --mat-card-subtitle-text-font: Bahnschrift;
    --mat-card-subtitle-text-line-height: 22px;
    --mat-card-subtitle-text-size: 14px;
    --mat-card-subtitle-text-tracking: 0.0071428571em;
    --mat-card-subtitle-text-weight: 500
}

.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: #3f51b5;
    --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25)
}

@keyframes mdc-linear-progress-buffering {
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: rgba(63, 81, 181, 0.25);
    background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25))
}

@media(forced-colors: active) {
    .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
        background-color: ButtonBorder
    }
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
    .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
        background-color: rgba(0,0,0,0);
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(63, 81, 181, 0.25)'/%3E%3C/svg%3E")
    }
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: rgba(63, 81, 181, 0.25);
    background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25))
}

.mat-mdc-progress-bar.mat-accent {
    --mdc-linear-progress-active-indicator-color: #ff4081;
    --mdc-linear-progress-track-color: rgba(255, 64, 129, 0.25)
}

@keyframes mdc-linear-progress-buffering {
}

.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: rgba(255, 64, 129, 0.25);
    background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25))
}

@media(forced-colors: active) {
    .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
        background-color: ButtonBorder
    }
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
    .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
        background-color: rgba(0,0,0,0);
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 64, 129, 0.25)'/%3E%3C/svg%3E")
    }
}

.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
    background-color: rgba(255, 64, 129, 0.25);
    background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25))
}

.mat-mdc-progress-bar.mat-warn {
    --mdc-linear-progress-active-indicator-color: #f44336;
    --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25)
}

@keyframes mdc-linear-progress-buffering {
}

.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: rgba(244, 67, 54, 0.25);
    background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25))
}

@media(forced-colors: active) {
    .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
        background-color: ButtonBorder
    }
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
    .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
        background-color: rgba(0,0,0,0);
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E")
    }
}

.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
    background-color: rgba(244, 67, 54, 0.25);
    background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25))
}

.mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: #616161;
    --mdc-plain-tooltip-supporting-text-color: #fff
}

.mat-mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-font: Bahnschrift;
    --mdc-plain-tooltip-supporting-text-size: 12px;
    --mdc-plain-tooltip-supporting-text-weight: 400;
    --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em
}

html {
    --mdc-filled-text-field-caret-color: #3f51b5;
    --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
    --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
    --mdc-filled-text-field-container-color: whitesmoke;
    --mdc-filled-text-field-disabled-container-color: #fafafa;
    --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
    --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
    --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
    --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
    --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
    --mdc-filled-text-field-error-focus-label-text-color: #f44336;
    --mdc-filled-text-field-error-label-text-color: #f44336;
    --mdc-filled-text-field-error-caret-color: #f44336;
    --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
    --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
    --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
    --mdc-filled-text-field-error-active-indicator-color: #f44336;
    --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
    --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
    --mdc-outlined-text-field-caret-color: #3f51b5;
    --mdc-outlined-text-field-focus-outline-color: #3f51b5;
    --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
    --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
    --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
    --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
    --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
    --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
    --mdc-outlined-text-field-error-caret-color: #f44336;
    --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
    --mdc-outlined-text-field-error-label-text-color: #f44336;
    --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
    --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
    --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
    --mdc-outlined-text-field-error-focus-outline-color: #f44336;
    --mdc-outlined-text-field-error-hover-outline-color: #f44336;
    --mdc-outlined-text-field-error-outline-color: #f44336;
    --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38)
}

.mat-mdc-form-field-error {
    color: var(--mdc-theme-error, #f44336)
}

.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mat-form-field-subscript-text-font);
    line-height: var(--mat-form-field-subscript-text-line-height);
    font-size: var(--mat-form-field-subscript-text-size);
    letter-spacing: var(--mat-form-field-subscript-text-tracking);
    font-weight: var(--mat-form-field-subscript-text-weight)
}

.mat-mdc-form-field-focus-overlay {
    background-color: rgba(0,0,0,.87)
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: .04
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: .12
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
    color: rgba(0,0,0,.54)
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
    color: rgba(63,81,181,.87)
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
    color: rgba(255,64,129,.87)
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
    color: rgba(244,67,54,.87)
}

.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
    color: rgba(0,0,0,.38)
}

.mat-mdc-form-field.mat-accent {
    --mdc-filled-text-field-caret-color: #ff4081;
    --mdc-filled-text-field-focus-active-indicator-color: #ff4081;
    --mdc-filled-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
    --mdc-outlined-text-field-caret-color: #ff4081;
    --mdc-outlined-text-field-focus-outline-color: #ff4081;
    --mdc-outlined-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87)
}

.mat-mdc-form-field.mat-warn {
    --mdc-filled-text-field-caret-color: #f44336;
    --mdc-filled-text-field-focus-active-indicator-color: #f44336;
    --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
    --mdc-outlined-text-field-caret-color: #f44336;
    --mdc-outlined-text-field-focus-outline-color: #f44336;
    --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87)
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-left: 1px solid rgba(0,0,0,0)
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-left: none;
    border-right: 1px solid rgba(0,0,0,0)
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY( -34.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    transform: var(--mat-mdc-form-field-label-transform)
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 16px;
    padding-bottom: 16px
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 16px;
    padding-bottom: 16px
}

html {
    --mdc-filled-text-field-label-text-font: Bahnschrift;
    --mdc-filled-text-field-label-text-size: 16px;
    --mdc-filled-text-field-label-text-tracking: 0.03125em;
    --mdc-filled-text-field-label-text-weight: 400;
    --mdc-outlined-text-field-label-text-font: Bahnschrift;
    --mdc-outlined-text-field-label-text-size: 16px;
    --mdc-outlined-text-field-label-text-tracking: 0.03125em;
    --mdc-outlined-text-field-label-text-weight: 400;
    --mat-form-field-container-text-font: Bahnschrift;
    --mat-form-field-container-text-line-height: 24px;
    --mat-form-field-container-text-size: 16px;
    --mat-form-field-container-text-tracking: 0.03125em;
    --mat-form-field-container-text-weight: 400;
    --mat-form-field-outlined-label-text-populated-size: 16px;
    --mat-form-field-subscript-text-font: Bahnschrift;
    --mat-form-field-subscript-text-line-height: 20px;
    --mat-form-field-subscript-text-size: 12px;
    --mat-form-field-subscript-text-tracking: 0.0333333333em;
    --mat-form-field-subscript-text-weight: 400
}

html {
    --mat-select-panel-background-color: white;
    --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
    --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
    --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
    --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
    --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
    --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
    --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87)
}

    html .mat-mdc-form-field.mat-accent {
        --mat-select-panel-background-color: white;
        --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
        --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
        --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
        --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
        --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
        --mat-select-focused-arrow-color: rgba(255, 64, 129, 0.87);
        --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87)
    }

    html .mat-mdc-form-field.mat-warn {
        --mat-select-panel-background-color: white;
        --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
        --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
        --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
        --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
        --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
        --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
        --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87)
    }

html {
    --mat-select-trigger-text-font: Bahnschrift;
    --mat-select-trigger-text-line-height: 24px;
    --mat-select-trigger-text-size: 16px;
    --mat-select-trigger-text-tracking: 0.03125em;
    --mat-select-trigger-text-weight: 400
}

html {
    --mat-autocomplete-background-color: white
}

.mat-mdc-dialog-container {
    --mdc-dialog-container-color: white;
    --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
    --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6)
}

/* .mat-mdc-dialog-container {
    --mdc-dialog-subhead-font: Roboto, sans-serif;
    --mdc-dialog-subhead-line-height: 32px;
    --mdc-dialog-subhead-size: 20px;
    --mdc-dialog-subhead-weight: 500;
    --mdc-dialog-subhead-tracking: 0.0125em;
    --mdc-dialog-supporting-text-font: Roboto, sans-serif;
    --mdc-dialog-supporting-text-line-height: 24px;
    --mdc-dialog-supporting-text-size: 16px;
    --mdc-dialog-supporting-text-weight: 400;
    --mdc-dialog-supporting-text-tracking: 0.03125em
} */

.mat-mdc-standard-chip {
    --mdc-chip-disabled-label-text-color: #212121;
    --mdc-chip-elevated-container-color: #e0e0e0;
    --mdc-chip-elevated-disabled-container-color: #e0e0e0;
    --mdc-chip-focus-state-layer-color: black;
    --mdc-chip-focus-state-layer-opacity: 0.12;
    --mdc-chip-label-text-color: #212121;
    --mdc-chip-with-icon-icon-color: #212121;
    --mdc-chip-with-icon-disabled-icon-color: #212121;
    --mdc-chip-with-icon-selected-icon-color: #212121;
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
    --mdc-chip-with-trailing-icon-trailing-icon-color: #212121
}

    .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
        --mdc-chip-disabled-label-text-color: white;
        --mdc-chip-elevated-container-color: #3f51b5;
        --mdc-chip-elevated-disabled-container-color: #3f51b5;
        --mdc-chip-focus-state-layer-color: black;
        --mdc-chip-focus-state-layer-opacity: 0.12;
        --mdc-chip-label-text-color: white;
        --mdc-chip-with-icon-icon-color: white;
        --mdc-chip-with-icon-disabled-icon-color: white;
        --mdc-chip-with-icon-selected-icon-color: white;
        --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
        --mdc-chip-with-trailing-icon-trailing-icon-color: white
    }

    .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
        --mdc-chip-disabled-label-text-color: white;
        --mdc-chip-elevated-container-color: #ff4081;
        --mdc-chip-elevated-disabled-container-color: #ff4081;
        --mdc-chip-focus-state-layer-color: black;
        --mdc-chip-focus-state-layer-opacity: 0.12;
        --mdc-chip-label-text-color: white;
        --mdc-chip-with-icon-icon-color: white;
        --mdc-chip-with-icon-disabled-icon-color: white;
        --mdc-chip-with-icon-selected-icon-color: white;
        --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
        --mdc-chip-with-trailing-icon-trailing-icon-color: white
    }

    .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
        --mdc-chip-disabled-label-text-color: white;
        --mdc-chip-elevated-container-color: #f44336;
        --mdc-chip-elevated-disabled-container-color: #f44336;
        --mdc-chip-focus-state-layer-color: black;
        --mdc-chip-focus-state-layer-opacity: 0.12;
        --mdc-chip-label-text-color: white;
        --mdc-chip-with-icon-icon-color: white;
        --mdc-chip-with-icon-disabled-icon-color: white;
        --mdc-chip-with-icon-selected-icon-color: white;
        --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
        --mdc-chip-with-trailing-icon-trailing-icon-color: white
    }

.mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-container-height: 32px
}

.mat-mdc-standard-chip {
    --mdc-chip-label-text-font: Bahnschrift;
    --mdc-chip-label-text-line-height: 20px;
    --mdc-chip-label-text-size: 14px;
    --mdc-chip-label-text-tracking: 0.0178571429em;
    --mdc-chip-label-text-weight: 400
}

.mat-mdc-slide-toggle {
    --mdc-switch-selected-focus-state-layer-color: #3949ab;
    --mdc-switch-selected-handle-color: #3949ab;
    --mdc-switch-selected-hover-state-layer-color: #3949ab;
    --mdc-switch-selected-pressed-state-layer-color: #3949ab;
    --mdc-switch-selected-focus-handle-color: #1a237e;
    --mdc-switch-selected-hover-handle-color: #1a237e;
    --mdc-switch-selected-pressed-handle-color: #1a237e;
    --mdc-switch-selected-focus-track-color: #7986cb;
    --mdc-switch-selected-hover-track-color: #7986cb;
    --mdc-switch-selected-pressed-track-color: #7986cb;
    --mdc-switch-selected-track-color: #7986cb;
    --mdc-switch-disabled-selected-handle-color: #424242;
    --mdc-switch-disabled-selected-icon-color: #fff;
    --mdc-switch-disabled-selected-track-color: #424242;
    --mdc-switch-disabled-unselected-handle-color: #424242;
    --mdc-switch-disabled-unselected-icon-color: #fff;
    --mdc-switch-disabled-unselected-track-color: #424242;
    --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
    --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --mdc-switch-handle-shadow-color: black;
    --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mdc-switch-selected-icon-color: #fff;
    --mdc-switch-unselected-focus-handle-color: #212121;
    --mdc-switch-unselected-focus-state-layer-color: #424242;
    --mdc-switch-unselected-focus-track-color: #e0e0e0;
    --mdc-switch-unselected-handle-color: #616161;
    --mdc-switch-unselected-hover-handle-color: #212121;
    --mdc-switch-unselected-hover-state-layer-color: #424242;
    --mdc-switch-unselected-hover-track-color: #e0e0e0;
    --mdc-switch-unselected-icon-color: #fff;
    --mdc-switch-unselected-pressed-handle-color: #212121;
    --mdc-switch-unselected-pressed-state-layer-color: #424242;
    --mdc-switch-unselected-pressed-track-color: #e0e0e0;
    --mdc-switch-unselected-track-color: #e0e0e0
}

    .mat-mdc-slide-toggle .mdc-form-field {
        color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87))
    }

    .mat-mdc-slide-toggle .mdc-switch--disabled + label {
        color: rgba(0,0,0,.38)
    }

    .mat-mdc-slide-toggle.mat-accent {
        --mdc-switch-selected-focus-state-layer-color: #d81b60;
        --mdc-switch-selected-handle-color: #d81b60;
        --mdc-switch-selected-hover-state-layer-color: #d81b60;
        --mdc-switch-selected-pressed-state-layer-color: #d81b60;
        --mdc-switch-selected-focus-handle-color: #880e4f;
        --mdc-switch-selected-hover-handle-color: #880e4f;
        --mdc-switch-selected-pressed-handle-color: #880e4f;
        --mdc-switch-selected-focus-track-color: #f06292;
        --mdc-switch-selected-hover-track-color: #f06292;
        --mdc-switch-selected-pressed-track-color: #f06292;
        --mdc-switch-selected-track-color: #f06292
    }

    .mat-mdc-slide-toggle.mat-warn {
        --mdc-switch-selected-focus-state-layer-color: #e53935;
        --mdc-switch-selected-handle-color: #e53935;
        --mdc-switch-selected-hover-state-layer-color: #e53935;
        --mdc-switch-selected-pressed-state-layer-color: #e53935;
        --mdc-switch-selected-focus-handle-color: #b71c1c;
        --mdc-switch-selected-hover-handle-color: #b71c1c;
        --mdc-switch-selected-pressed-handle-color: #b71c1c;
        --mdc-switch-selected-focus-track-color: #e57373;
        --mdc-switch-selected-hover-track-color: #e57373;
        --mdc-switch-selected-pressed-track-color: #e57373;
        --mdc-switch-selected-track-color: #e57373
    }

.mat-mdc-slide-toggle {
    --mdc-switch-state-layer-size: 48px
}

.mat-mdc-slide-toggle {
    --mat-slide-toggle-label-text-font: Bahnschrift;
    --mat-slide-toggle-label-text-size: 14px;
    --mat-slide-toggle-label-text-tracking: 0.0178571429em;
    --mat-slide-toggle-label-text-line-height: 20px;
    --mat-slide-toggle-label-text-weight: 400
}

    .mat-mdc-slide-toggle .mdc-form-field {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: Bahnschrift;
        font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Bahnschrift));
        font-size: 0.875rem;
        font-size: var(--mdc-typography-body2-font-size, 0.875rem);
        line-height: 1.25rem;
        line-height: var(--mdc-typography-body2-line-height, 1.25rem);
        font-weight: 400;
        font-weight: var(--mdc-typography-body2-font-weight, 400);
        letter-spacing: 0.0178571429em;
        letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
        text-decoration: inherit;
        text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
        text-transform: inherit;
        text-transform: var(--mdc-typography-body2-text-transform, inherit)
    }

.mat-mdc-radio-button .mdc-form-field {
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87))
}

.mat-mdc-radio-button.mat-primary {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: #3f51b5;
    --mdc-radio-selected-hover-icon-color: #3f51b5;
    --mdc-radio-selected-icon-color: #3f51b5;
    --mdc-radio-selected-pressed-icon-color: #3f51b5;
    --mat-radio-ripple-color: #000;
    --mat-radio-checked-ripple-color: #3f51b5;
    --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38)
}

.mat-mdc-radio-button.mat-accent {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: #ff4081;
    --mdc-radio-selected-hover-icon-color: #ff4081;
    --mdc-radio-selected-icon-color: #ff4081;
    --mdc-radio-selected-pressed-icon-color: #ff4081;
    --mat-radio-ripple-color: #000;
    --mat-radio-checked-ripple-color: #ff4081;
    --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38)
}

.mat-mdc-radio-button.mat-warn {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: #f44336;
    --mdc-radio-selected-hover-icon-color: #f44336;
    --mdc-radio-selected-icon-color: #f44336;
    --mdc-radio-selected-pressed-icon-color: #f44336;
    --mat-radio-ripple-color: #000;
    --mat-radio-checked-ripple-color: #f44336;
    --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38)
}

.mat-mdc-radio-button .mdc-radio {
    --mdc-radio-state-layer-size: 40px
}

.mat-mdc-radio-button .mdc-form-field {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Bahnschrift));
    font-size: var(--mdc-typography-body2-font-size, 14px);
    line-height: var(--mdc-typography-body2-line-height, 20px);
    font-weight: var(--mdc-typography-body2-font-weight, 400);
    letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
    text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
    text-transform: var(--mdc-typography-body2-text-transform, none)
}

.mat-mdc-slider {
    --mdc-slider-label-container-color: black;
    --mdc-slider-label-label-text-color: white;
    --mdc-slider-disabled-handle-color: #000;
    --mdc-slider-disabled-active-track-color: #000;
    --mdc-slider-disabled-inactive-track-color: #000;
    --mdc-slider-with-tick-marks-disabled-container-color: #000;
    --mat-mdc-slider-value-indicator-opacity: 0.6
}

    .mat-mdc-slider.mat-primary {
        --mdc-slider-handle-color: #3f51b5;
        --mdc-slider-focus-handle-color: #3f51b5;
        --mdc-slider-hover-handle-color: #3f51b5;
        --mdc-slider-active-track-color: #3f51b5;
        --mdc-slider-inactive-track-color: #3f51b5;
        --mdc-slider-with-tick-marks-active-container-color: #fff;
        --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
        --mat-mdc-slider-ripple-color: #3f51b5;
        --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
        --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2)
    }

    .mat-mdc-slider.mat-accent {
        --mdc-slider-handle-color: #ff4081;
        --mdc-slider-focus-handle-color: #ff4081;
        --mdc-slider-hover-handle-color: #ff4081;
        --mdc-slider-active-track-color: #ff4081;
        --mdc-slider-inactive-track-color: #ff4081;
        --mdc-slider-with-tick-marks-active-container-color: #fff;
        --mdc-slider-with-tick-marks-inactive-container-color: #ff4081;
        --mat-mdc-slider-ripple-color: #ff4081;
        --mat-mdc-slider-hover-ripple-color: rgba(255, 64, 129, 0.05);
        --mat-mdc-slider-focus-ripple-color: rgba(255, 64, 129, 0.2)
    }

    .mat-mdc-slider.mat-warn {
        --mdc-slider-handle-color: #f44336;
        --mdc-slider-focus-handle-color: #f44336;
        --mdc-slider-hover-handle-color: #f44336;
        --mdc-slider-active-track-color: #f44336;
        --mdc-slider-inactive-track-color: #f44336;
        --mdc-slider-with-tick-marks-active-container-color: #fff;
        --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
        --mat-mdc-slider-ripple-color: #f44336;
        --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
        --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2)
    }

.mat-mdc-slider {
    --mdc-slider-label-label-text-font: Bahnschrift;
    --mdc-slider-label-label-text-size: 14px;
    --mdc-slider-label-label-text-line-height: 22px;
    --mdc-slider-label-label-text-tracking: 0.0071428571em;
    --mdc-slider-label-label-text-weight: 500
}

html {
    --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
    --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
    --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
    --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
    --mat-menu-container-color: white
}

/* html {
    --mat-menu-item-label-text-font: Roboto, sans-serif;
    --mat-menu-item-label-text-size: 16px;
    --mat-menu-item-label-text-tracking: 0.03125em;
    --mat-menu-item-label-text-line-height: 24px;
    --mat-menu-item-label-text-weight: 400
}
 */
.mat-mdc-list-base {
    --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
    --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-disabled-label-text-color: black;
    --mdc-list-list-item-disabled-leading-icon-color: black;
    --mdc-list-list-item-disabled-trailing-icon-color: black;
    --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-list-list-item-hover-state-layer-color: black;
    --mdc-list-list-item-hover-state-layer-opacity: 0.04;
    --mdc-list-list-item-focus-state-layer-color: black;
    --mdc-list-list-item-focus-state-layer-opacity: 0.12
}

.mdc-list-item__start, .mdc-list-item__end {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: #3f51b5;
    --mdc-radio-selected-hover-icon-color: #3f51b5;
    --mdc-radio-selected-icon-color: #3f51b5;
    --mdc-radio-selected-pressed-icon-color: #3f51b5
}

.mat-accent .mdc-list-item__start, .mat-accent .mdc-list-item__end {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: #ff4081;
    --mdc-radio-selected-hover-icon-color: #ff4081;
    --mdc-radio-selected-icon-color: #ff4081;
    --mdc-radio-selected-pressed-icon-color: #ff4081
}

.mat-warn .mdc-list-item__start, .mat-warn .mdc-list-item__end {
    --mdc-radio-disabled-selected-icon-color: #000;
    --mdc-radio-disabled-unselected-icon-color: #000;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: #f44336;
    --mdc-radio-selected-hover-icon-color: #f44336;
    --mdc-radio-selected-icon-color: #f44336;
    --mdc-radio-selected-pressed-icon-color: #f44336
}

.mat-mdc-list-option {
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #3f51b5;
    --mdc-checkbox-selected-hover-icon-color: #3f51b5;
    --mdc-checkbox-selected-icon-color: #3f51b5;
    --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
    --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
    --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
    --mdc-checkbox-unselected-focus-state-layer-color: black;
    --mdc-checkbox-unselected-hover-state-layer-color: black;
    --mdc-checkbox-unselected-pressed-state-layer-color: black
}

    .mat-mdc-list-option.mat-accent {
        --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
        --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
        --mdc-checkbox-selected-checkmark-color: #fff;
        --mdc-checkbox-selected-focus-icon-color: #ff4081;
        --mdc-checkbox-selected-hover-icon-color: #ff4081;
        --mdc-checkbox-selected-icon-color: #ff4081;
        --mdc-checkbox-selected-pressed-icon-color: #ff4081;
        --mdc-checkbox-unselected-focus-icon-color: #212121;
        --mdc-checkbox-unselected-hover-icon-color: #212121;
        --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
        --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
        --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
        --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
        --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
        --mdc-checkbox-unselected-focus-state-layer-color: black;
        --mdc-checkbox-unselected-hover-state-layer-color: black;
        --mdc-checkbox-unselected-pressed-state-layer-color: black
    }

    .mat-mdc-list-option.mat-warn {
        --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
        --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
        --mdc-checkbox-selected-checkmark-color: #fff;
        --mdc-checkbox-selected-focus-icon-color: #f44336;
        --mdc-checkbox-selected-hover-icon-color: #f44336;
        --mdc-checkbox-selected-icon-color: #f44336;
        --mdc-checkbox-selected-pressed-icon-color: #f44336;
        --mdc-checkbox-unselected-focus-icon-color: #212121;
        --mdc-checkbox-unselected-hover-icon-color: #212121;
        --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
        --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
        --mdc-checkbox-selected-focus-state-layer-color: #f44336;
        --mdc-checkbox-selected-hover-state-layer-color: #f44336;
        --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
        --mdc-checkbox-unselected-focus-state-layer-color: black;
        --mdc-checkbox-unselected-hover-state-layer-color: black;
        --mdc-checkbox-unselected-pressed-state-layer-color: black
    }

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text, .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
    color: #3f51b5
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start, .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
    color: #3f51b5
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start, .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content, .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
    opacity: 1
}

.mat-mdc-list-base {
    --mdc-list-list-item-one-line-container-height: 48px;
    --mdc-list-list-item-two-line-container-height: 64px;
    --mdc-list-list-item-three-line-container-height: 88px
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
    height: 56px
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
    height: 72px
}

.mat-mdc-list-base {
    --mdc-list-list-item-label-text-font: Bahnschrift;
    --mdc-list-list-item-label-text-line-height: 24px;
    --mdc-list-list-item-label-text-size: 16px;
    --mdc-list-list-item-label-text-tracking: 0.03125em;
    --mdc-list-list-item-label-text-weight: 400;
    --mdc-list-list-item-supporting-text-font: Bahnschrift;
    --mdc-list-list-item-supporting-text-line-height: 20px;
    --mdc-list-list-item-supporting-text-size: 14px;
    --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
    --mdc-list-list-item-supporting-text-weight: 400;
    --mdc-list-list-item-trailing-supporting-text-font: Bahnschrift;
    --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
    --mdc-list-list-item-trailing-supporting-text-size: 12px;
    --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
    --mdc-list-list-item-trailing-supporting-text-weight: 400
}

.mdc-list-group__subheader {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    font-family: Bahnschrift;
    letter-spacing: .009375em
}

html {
    --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
    --mat-paginator-container-background-color: white;
    --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
    --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12)
}

html {
    --mat-paginator-container-size: 56px
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
    min-height: 40px
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY( -26.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    transform: var(--mat-mdc-form-field-label-transform)
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 8px;
    padding-bottom: 8px
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 8px;
    padding-bottom: 8px
}

.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 8px;
    padding-bottom: 8px
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
    display: none
}

html {
    --mat-paginator-container-text-font: Bahnschrift;
    --mat-paginator-container-text-line-height: 20px;
    --mat-paginator-container-text-size: 12px;
    --mat-paginator-container-text-tracking: 0.0333333333em;
    --mat-paginator-container-text-weight: 400;
    --mat-paginator-select-trigger-text-size: 12px
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #3f51b5;
    --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
    --mat-tab-header-pagination-icon-color: #000;
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-label-text-color: #3f51b5;
    --mat-tab-header-active-ripple-color: #3f51b5;
    --mat-tab-header-inactive-ripple-color: #3f51b5;
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: #3f51b5;
    --mat-tab-header-active-hover-label-text-color: #3f51b5;
    --mat-tab-header-active-focus-indicator-color: #3f51b5;
    --mat-tab-header-active-hover-indicator-color: #3f51b5
}

    .mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
        --mdc-tab-indicator-active-indicator-color: #ff4081;
        --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
        --mat-tab-header-pagination-icon-color: #000;
        --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
        --mat-tab-header-active-label-text-color: #ff4081;
        --mat-tab-header-active-ripple-color: #ff4081;
        --mat-tab-header-inactive-ripple-color: #ff4081;
        --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
        --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
        --mat-tab-header-active-focus-label-text-color: #ff4081;
        --mat-tab-header-active-hover-label-text-color: #ff4081;
        --mat-tab-header-active-focus-indicator-color: #ff4081;
        --mat-tab-header-active-hover-indicator-color: #ff4081
    }

    .mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
        --mdc-tab-indicator-active-indicator-color: #f44336;
        --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
        --mat-tab-header-pagination-icon-color: #000;
        --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
        --mat-tab-header-active-label-text-color: #f44336;
        --mat-tab-header-active-ripple-color: #f44336;
        --mat-tab-header-inactive-ripple-color: #f44336;
        --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
        --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
        --mat-tab-header-active-focus-label-text-color: #f44336;
        --mat-tab-header-active-hover-label-text-color: #f44336;
        --mat-tab-header-active-focus-indicator-color: #f44336;
        --mat-tab-header-active-hover-indicator-color: #f44336
    }

    .mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
        --mat-tab-header-with-background-background-color: #3f51b5;
        --mat-tab-header-with-background-foreground-color: white
    }

    .mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
        --mat-tab-header-with-background-background-color: #ff4081;
        --mat-tab-header-with-background-foreground-color: white
    }

    .mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
        --mat-tab-header-with-background-background-color: #f44336;
        --mat-tab-header-with-background-foreground-color: white
    }

.mat-mdc-tab-header {
    --mdc-secondary-navigation-tab-container-height: 48px
}

.mat-mdc-tab-header {
    --mat-tab-header-label-text-font: Bahnschrift;
    --mat-tab-header-label-text-size: 14px;
    --mat-tab-header-label-text-tracking: 0.0892857143em;
    --mat-tab-header-label-text-line-height: 36px;
    --mat-tab-header-label-text-weight: 500
}

.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #3f51b5;
    --mdc-checkbox-selected-hover-icon-color: #3f51b5;
    --mdc-checkbox-selected-icon-color: #3f51b5;
    --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
    --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
    --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
    --mdc-checkbox-unselected-focus-state-layer-color: black;
    --mdc-checkbox-unselected-hover-state-layer-color: black;
    --mdc-checkbox-unselected-pressed-state-layer-color: black
}

.mat-mdc-checkbox.mat-warn {
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #f44336;
    --mdc-checkbox-selected-hover-icon-color: #f44336;
    --mdc-checkbox-selected-icon-color: #f44336;
    --mdc-checkbox-selected-pressed-icon-color: #f44336;
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #f44336;
    --mdc-checkbox-selected-hover-state-layer-color: #f44336;
    --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
    --mdc-checkbox-unselected-focus-state-layer-color: black;
    --mdc-checkbox-unselected-hover-state-layer-color: black;
    --mdc-checkbox-unselected-pressed-state-layer-color: black
}

/* .mat-mdc-checkbox .mdc-form-field {
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87))
} */
.mat-mdc-checkbox .mdc-form-field {
    .mdc-label {
        margin-bottom: 0;
    }
}
@media only screen and (min-width: 761px) {
    .mat-mdc-checkbox .mdc-form-field {
        color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87))
    }
}
@media only screen and (max-width: 760px) {
    .mat-mdc-checkbox .mdc-form-field {
        color: white;
    }
}

.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
    color: rgba(0,0,0,.38)
}

html {
    --mdc-checkbox-state-layer-size: 40px
}

.mat-mdc-checkbox .mdc-form-field {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Bahnschrift));
    font-size: var(--mdc-typography-body2-font-size, 14px);
    line-height: var(--mdc-typography-body2-line-height, 20px);
    font-weight: var(--mdc-typography-body2-font-weight, 400);
    letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
    text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
    text-transform: var(--mdc-typography-body2-text-transform, none)
}

.mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: #000
}

.mat-mdc-button.mat-primary {
    --mdc-text-button-label-text-color: #3f51b5
}

.mat-mdc-button.mat-accent {
    --mdc-text-button-label-text-color: #ff4081
}

.mat-mdc-button.mat-warn {
    --mdc-text-button-label-text-color: #f44336
}

.mat-mdc-button[disabled][disabled] {
    --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
    --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38)
}

.mat-mdc-unelevated-button.mat-unthemed {
    --mdc-filled-button-container-color: #fff;
    --mdc-filled-button-label-text-color: #000
}

.mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-container-color: #3f51b5;
    --mdc-filled-button-label-text-color: #fff
}

.mat-mdc-unelevated-button.mat-accent {
    --mdc-filled-button-container-color: #ff4081;
    --mdc-filled-button-label-text-color: #fff
}

.mat-mdc-unelevated-button.mat-warn {
    --mdc-filled-button-container-color: #f44336;
    --mdc-filled-button-label-text-color: #fff
}

.mat-mdc-unelevated-button[disabled][disabled] {
    --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
    --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
    --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
    --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38)
}

.mat-mdc-raised-button.mat-unthemed {
    --mdc-protected-button-container-color: #fff;
    --mdc-protected-button-label-text-color: #000
}

.mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-container-color: #3f51b5;
    --mdc-protected-button-label-text-color: #fff
}

.mat-mdc-raised-button.mat-accent {
    --mdc-protected-button-container-color: #ff4081;
    --mdc-protected-button-label-text-color: #fff
}

.mat-mdc-raised-button.mat-warn {
    --mdc-protected-button-container-color: #f44336;
    --mdc-protected-button-label-text-color: #fff
}

.mat-mdc-raised-button[disabled][disabled] {
    --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
    --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
    --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
    --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
    --mdc-protected-button-container-elevation: 0
}

.mat-mdc-outlined-button {
    --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12)
}

    .mat-mdc-outlined-button.mat-unthemed {
        --mdc-outlined-button-label-text-color: #000
    }

    .mat-mdc-outlined-button.mat-primary {
        --mdc-outlined-button-label-text-color: #3f51b5
    }

    .mat-mdc-outlined-button.mat-accent {
        --mdc-outlined-button-label-text-color: #ff4081
    }

    .mat-mdc-outlined-button.mat-warn {
        --mdc-outlined-button-label-text-color: #f44336
    }

    .mat-mdc-outlined-button[disabled][disabled] {
        --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
        --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
        --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
        --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12)
    }

.mat-mdc-button, .mat-mdc-outlined-button {
    --mat-mdc-button-persistent-ripple-color: #000;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1)
}

    .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
        opacity: .04
    }

    .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
        opacity: .12
    }

    .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
        opacity: .12
    }

    .mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
        --mat-mdc-button-persistent-ripple-color: #3f51b5;
        --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1)
    }

    .mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
        --mat-mdc-button-persistent-ripple-color: #ff4081;
        --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1)
    }

    .mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
        --mat-mdc-button-persistent-ripple-color: #f44336;
        --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1)
    }

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
    --mat-mdc-button-persistent-ripple-color: #000;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1)
}

    .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
        opacity: .04
    }

    .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
        opacity: .12
    }

    .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
        opacity: .12
    }

    .mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
        --mat-mdc-button-persistent-ripple-color: #fff;
        --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1)
    }

    .mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
        --mat-mdc-button-persistent-ripple-color: #fff;
        --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1)
    }

    .mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
        --mat-mdc-button-persistent-ripple-color: #fff;
        --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1)
    }

    .mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
        height: 36px
    }

.mdc-button {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Bahnschrift));
    font-size: var(--mdc-typography-button-font-size, 14px);
    line-height: var(--mdc-typography-button-line-height, 36px);
    font-weight: var(--mdc-typography-button-font-weight, 500);
    letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
    text-decoration: var(--mdc-typography-button-text-decoration, none);
    text-transform: var(--mdc-typography-button-text-transform, none)
}

.mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: #000;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
    --mdc-icon-button-icon-color: inherit;
    --mat-mdc-button-persistent-ripple-color: #000;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1)
}

    .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
        opacity: .04
    }

    .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
        opacity: .12
    }

    .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
        opacity: .12
    }

    .mat-mdc-icon-button.mat-primary {
        --mat-mdc-button-persistent-ripple-color: #6200ee;
        --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1)
    }

    .mat-mdc-icon-button.mat-accent {
        --mat-mdc-button-persistent-ripple-color: #018786;
        --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1)
    }

    .mat-mdc-icon-button.mat-warn {
        --mat-mdc-button-persistent-ripple-color: #b00020;
        --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1)
    }

    .mat-mdc-icon-button.mat-primary {
        --mdc-icon-button-icon-color: #3f51b5;
        --mat-mdc-button-persistent-ripple-color: #3f51b5;
        --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1)
    }

    .mat-mdc-icon-button.mat-accent {
        --mdc-icon-button-icon-color: #ff4081;
        --mat-mdc-button-persistent-ripple-color: #ff4081;
        --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1)
    }

    .mat-mdc-icon-button.mat-warn {
        --mdc-icon-button-icon-color: #f44336;
        --mat-mdc-button-persistent-ripple-color: #f44336;
        --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1)
    }

    .mat-mdc-icon-button[disabled][disabled] {
        --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
        --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38)
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 48px;
        width: var(--mdc-icon-button-state-layer-size);
        height: var(--mdc-icon-button-state-layer-size);
        padding: 12px
    }

.mat-mdc-fab, .mat-mdc-mini-fab {
    --mat-mdc-button-persistent-ripple-color: #000;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1)
}

    .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
        opacity: .04
    }

    .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
        opacity: .12
    }

    .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
        opacity: .12
    }

    .mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
        --mat-mdc-button-persistent-ripple-color: #fff;
        --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1)
    }

    .mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
        --mat-mdc-button-persistent-ripple-color: #fff;
        --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1)
    }

    .mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
        --mat-mdc-button-persistent-ripple-color: #fff;
        --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1)
    }

    .mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
        --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
        --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
        --mat-mdc-fab-color: rgba(0, 0, 0, 0.38)
    }

    .mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
        --mdc-fab-container-color: white;
        --mdc-fab-icon-color: black;
        --mat-mdc-fab-color: #000
    }

    .mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
        --mdc-fab-container-color: #3f51b5;
        --mdc-fab-icon-color: white;
        --mat-mdc-fab-color: #fff
    }

    .mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
        --mdc-fab-container-color: #ff4081;
        --mdc-fab-icon-color: white;
        --mat-mdc-fab-color: #fff
    }

    .mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
        --mdc-fab-container-color: #f44336;
        --mdc-fab-icon-color: white;
        --mat-mdc-fab-color: #fff
    }

.mdc-fab--extended {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Bahnschrift));
    font-size: var(--mdc-typography-button-font-size, 14px);
    line-height: var(--mdc-typography-button-line-height, 36px);
    font-weight: var(--mdc-typography-button-font-weight, 500);
    letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
    text-decoration: var(--mdc-typography-button-text-decoration, none);
    text-transform: var(--mdc-typography-button-text-transform, none)
}

.mat-mdc-extended-fab {
    --mdc-extended-fab-label-text-font: Bahnschrift;
    --mdc-extended-fab-label-text-size: 14px;
    --mdc-extended-fab-label-text-tracking: 0.0892857143em;
    --mdc-extended-fab-label-text-weight: 500
}

.mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: #333333;
    --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
    --mat-snack-bar-button-color: #ff4081
}

.mat-mdc-snack-bar-container {
    --mdc-snackbar-supporting-text-font: Bahnschrift;
    --mdc-snackbar-supporting-text-line-height: 20px;
    --mdc-snackbar-supporting-text-size: 14px;
    --mdc-snackbar-supporting-text-weight: 400
}

html {
    --mat-table-background-color: white;
    --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
    --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
    --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12)
}

/* html {
    --mat-table-header-container-height: 56px;
    --mat-table-footer-container-height: 52px;
    --mat-table-row-item-container-height: 52px
} */

html {
    --mat-table-header-headline-font: Bahnschrift;
    --mat-table-header-headline-line-height: 22px;
    /* --mat-table-header-headline-size: 14px;
    --mat-table-header-headline-weight: 500; */
    --mat-table-header-headline-tracking: 0.0071428571em;
    --mat-table-row-item-label-text-font: Bahnschrift;
    --mat-table-row-item-label-text-line-height: 20px;
    --mat-table-row-item-label-text-size: 14px;
    --mat-table-row-item-label-text-weight: 400;
    --mat-table-row-item-label-text-tracking: 0.0178571429em;
    --mat-table-footer-supporting-text-font: Bahnschrift;
    --mat-table-footer-supporting-text-line-height: 20px;
    --mat-table-footer-supporting-text-size: 14px;
    --mat-table-footer-supporting-text-weight: 400;
    --mat-table-footer-supporting-text-tracking: 0.0178571429em
}

    .mat-mdc-progress-spinner.mat-accent {
        --mdc-circular-progress-active-indicator-color: #ff4081
    }

    .mat-mdc-progress-spinner.mat-warn {
        --mdc-circular-progress-active-indicator-color: #f44336
    }

.mat-badge {
    position: relative
}

    .mat-badge.mat-badge {
        overflow: visible
    }

.mat-badge-content {
    position: absolute;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    transition: transform 200ms ease-in-out;
    transform: scale(0.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    background-color: var(--mat-badge-background-color);
    color: var(--mat-badge-text-color);
    font-family: Bahnschrift;
    font-family: var(--mat-badge-text-font, Bahnschrift);
    font-size: 12px;
    font-size: var(--mat-badge-text-size, 12px);
    font-weight: 600;
    font-weight: var(--mat-badge-text-weight, 600)
}

.cdk-high-contrast-active .mat-badge-content {
    outline: solid 1px;
    border-radius: 0
}

.mat-badge-disabled .mat-badge-content {
    background-color: var(--mat-badge-disabled-state-background-color);
    color: var(--mat-badge-disabled-state-text-color)
}

.mat-badge-hidden .mat-badge-content {
    display: none
}

.ng-animate-disabled .mat-badge-content, .mat-badge-content._mat-animation-noopable {
    transition: none
}

.mat-badge-content.mat-badge-active {
    transform: none
}

.mat-badge-small .mat-badge-content {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 9px;
    font-size: var(--mat-badge-small-size-text-size, 9px)
}

.mat-badge-small.mat-badge-above .mat-badge-content {
    top: -8px
}

.mat-badge-small.mat-badge-below .mat-badge-content {
    bottom: -8px
}

.mat-badge-small.mat-badge-before .mat-badge-content {
    left: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
    left: auto;
    right: -16px
}

.mat-badge-small.mat-badge-after .mat-badge-content {
    right: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
    right: auto;
    left: -16px
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: auto;
    right: -8px
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: auto;
    left: -8px
}

.mat-badge-medium .mat-badge-content {
    width: 22px;
    height: 22px;
    line-height: 22px
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
    top: -11px
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
    bottom: -11px
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
    left: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
    left: auto;
    right: -22px
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
    right: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
    right: auto;
    left: -22px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: auto;
    right: -11px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: auto;
    left: -11px
}

.mat-badge-large .mat-badge-content {
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 24px;
    font-size: var(--mat-badge-large-size-text-size, 24px)
}

.mat-badge-large.mat-badge-above .mat-badge-content {
    top: -14px
}

.mat-badge-large.mat-badge-below .mat-badge-content {
    bottom: -14px
}

.mat-badge-large.mat-badge-before .mat-badge-content {
    left: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
    left: auto;
    right: -28px
}

.mat-badge-large.mat-badge-after .mat-badge-content {
    right: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
    right: auto;
    left: -28px
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: auto;
    right: -14px
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: auto;
    left: -14px
}

html {
    --mat-badge-background-color: #3f51b5;
    --mat-badge-text-color: white;
    --mat-badge-disabled-state-background-color: #b9b9b9;
    --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38)
}

.mat-badge-accent {
    --mat-badge-background-color: #ff4081;
    --mat-badge-text-color: white
}

.mat-badge-warn {
    --mat-badge-background-color: #f44336;
    --mat-badge-text-color: white
}

html {
    --mat-badge-text-font: Bahnschrift;
    --mat-badge-text-size: 12px;
    --mat-badge-text-weight: 600;
    --mat-badge-small-size-text-size: 9px;
    --mat-badge-large-size-text-size: 24px
}

html {
    --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
    --mat-bottom-sheet-container-background-color: white
}

html {
    --mat-bottom-sheet-container-text-font: Bahnschrift;
    --mat-bottom-sheet-container-text-line-height: 20px;
    --mat-bottom-sheet-container-text-size: 14px;
    --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
    --mat-bottom-sheet-container-text-weight: 400
}

html {
    --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
    --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
    --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
    --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
    --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
    --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
    --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
    --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
    --mat-standard-button-toggle-background-color: white;
    --mat-standard-button-toggle-state-layer-color: black;
    --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
    --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
    --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
    --mat-standard-button-toggle-disabled-state-background-color: white;
    --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
    --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
    --mat-standard-button-toggle-divider-color: #e0e0e0
}

html {
    --mat-standard-button-toggle-height: 48px
}

html {
    --mat-legacy-button-toggle-text-font: Bahnschrift;
    --mat-standard-button-toggle-text-font: Bahnschrift;
}

html {
    --mat-datepicker-calendar-date-selected-state-text-color: white;
    --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
    --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
    --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
    --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
    --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
    --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
    --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
    --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
    --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
    --mat-datepicker-calendar-date-outline-color: transparent;
    --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
    --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
    --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
    --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
    --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
    --mat-datepicker-calendar-container-background-color: white;
    --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87)
}

.mat-datepicker-content.mat-accent {
    --mat-datepicker-calendar-date-selected-state-text-color: white;
    --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
    --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
    --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
    --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e
}

.mat-datepicker-content.mat-warn {
    --mat-datepicker-calendar-date-selected-state-text-color: white;
    --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
    --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
    --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
    --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
    --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
    --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
    --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e
}

.mat-datepicker-toggle-active.mat-accent {
    --mat-datepicker-toggle-active-state-icon-color: #ff4081
}

.mat-datepicker-toggle-active.mat-warn {
    --mat-datepicker-toggle-active-state-icon-color: #f44336
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
    padding: 8px
}

    .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
        display: none
    }

html {
    --mat-datepicker-calendar-text-font: Bahnschrift;
    --mat-datepicker-calendar-text-size: 13px;
    --mat-datepicker-calendar-body-label-text-size: 14px;
    --mat-datepicker-calendar-body-label-text-weight: 500;
    --mat-datepicker-calendar-period-button-text-size: 14px;
    --mat-datepicker-calendar-period-button-text-weight: 500;
    --mat-datepicker-calendar-header-text-size: 11px;
    --mat-datepicker-calendar-header-text-weight: 400
}

html {
    --mat-divider-color: rgba(0, 0, 0, 0.12)
}

html {
    --mat-expansion-container-background-color: white;
    --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
    --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
    --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
    --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
    --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
    --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
    --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
    --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54)
}

html {
    --mat-expansion-header-collapsed-state-height: 48px;
    --mat-expansion-header-expanded-state-height: 64px
}

html {
    --mat-expansion-header-text-font: Bahnschrift;
    --mat-expansion-header-text-size: 14px;
    --mat-expansion-header-text-weight: 500;
    --mat-expansion-header-text-line-height: inherit;
    --mat-expansion-header-text-tracking: inherit;
    --mat-expansion-container-text-font: Bahnschrift;
    --mat-expansion-container-text-line-height: 20px;
    --mat-expansion-container-text-size: 14px;
    --mat-expansion-container-text-tracking: 0.0178571429em;
    --mat-expansion-container-text-weight: 400
}

html {
    --mat-grid-list-tile-header-primary-text-size: 14px;
    --mat-grid-list-tile-header-secondary-text-size: 12px;
    --mat-grid-list-tile-footer-primary-text-size: 14px;
    --mat-grid-list-tile-footer-secondary-text-size: 12px
}

html {
    --mat-icon-color: inherit
}

.mat-icon.mat-primary {
    --mat-icon-color: #3f51b5
}

.mat-icon.mat-accent {
    --mat-icon-color: #ff4081
}

.mat-icon.mat-warn {
    --mat-icon-color: #f44336
}

html {
    --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
    --mat-sidenav-container-background-color: white;
    --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
    --mat-sidenav-content-background-color: #fafafa;
    --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
    --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6)
}

html {
    --mat-stepper-header-icon-foreground-color: white;
    --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
    --mat-stepper-header-selected-state-icon-foreground-color: white;
    --mat-stepper-header-done-state-icon-background-color: #3f51b5;
    --mat-stepper-header-done-state-icon-foreground-color: white;
    --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
    --mat-stepper-header-edit-state-icon-foreground-color: white;
    --mat-stepper-container-color: white;
    --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
    --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
    --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
    --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
    --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
    --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
    --mat-stepper-header-error-state-label-text-color: #f44336;
    --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
    --mat-stepper-header-error-state-icon-foreground-color: #f44336;
    --mat-stepper-header-error-state-icon-background-color: transparent
}

    html .mat-step-header.mat-accent {
        --mat-stepper-header-icon-foreground-color: white;
        --mat-stepper-header-selected-state-icon-background-color: #ff4081;
        --mat-stepper-header-selected-state-icon-foreground-color: white;
        --mat-stepper-header-done-state-icon-background-color: #ff4081;
        --mat-stepper-header-done-state-icon-foreground-color: white;
        --mat-stepper-header-edit-state-icon-background-color: #ff4081;
        --mat-stepper-header-edit-state-icon-foreground-color: white
    }

    html .mat-step-header.mat-warn {
        --mat-stepper-header-icon-foreground-color: white;
        --mat-stepper-header-selected-state-icon-background-color: #f44336;
        --mat-stepper-header-selected-state-icon-foreground-color: white;
        --mat-stepper-header-done-state-icon-background-color: #f44336;
        --mat-stepper-header-done-state-icon-foreground-color: white;
        --mat-stepper-header-edit-state-icon-background-color: #f44336;
        --mat-stepper-header-edit-state-icon-foreground-color: white
    }

html {
    --mat-stepper-header-height: 72px
}

html {
    --mat-stepper-container-text-font: Bahnschrift;
    --mat-stepper-header-label-text-font: Bahnschrift;
    --mat-stepper-header-label-text-size: 14px;
    --mat-stepper-header-label-text-weight: 400;
    --mat-stepper-header-error-state-label-text-size: 16px;
    --mat-stepper-header-selected-state-label-text-size: 16px;
    --mat-stepper-header-selected-state-label-text-weight: 400
}

.mat-sort-header-arrow {
    color: #757575
}

html {
    --mat-toolbar-container-background-color: whitesmoke;
    --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87)
}

.mat-toolbar.mat-primary {
    --mat-toolbar-container-background-color: #3f51b5;
    --mat-toolbar-container-text-color: white
}

.mat-toolbar.mat-accent {
    --mat-toolbar-container-background-color: #ff4081;
    --mat-toolbar-container-text-color: white
}

.mat-toolbar.mat-warn {
    --mat-toolbar-container-background-color: #f44336;
    --mat-toolbar-container-text-color: white
}

html {
    --mat-toolbar-standard-height: 64px;
    --mat-toolbar-mobile-height: 56px
}

html {
    --mat-toolbar-title-text-font: Bahnschrift;
    --mat-toolbar-title-text-line-height: 32px;
    --mat-toolbar-title-text-size: 20px;
    --mat-toolbar-title-text-tracking: 0.0125em;
    --mat-toolbar-title-text-weight: 500
}

.mat-tree {
    background: #fff
}

.mat-tree-node, .mat-nested-tree-node {
    color: rgba(0,0,0,.87)
}

.mat-tree-node {
    min-height: 48px
}

.mat-tree {
    font-family: Bahnschrift
}

.mat-tree-node, .mat-nested-tree-node {
    font-weight: 400;
    font-size: 14px
}

.mat-h1, .mat-headline-5, .mat-typography .mat-h1, .mat-typography .mat-headline-5, .mat-typography h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    font-family: Bahnschrift;
    letter-spacing: normal;
    margin: 0 0 16px
}

.mat-h2, .mat-headline-6, .mat-typography .mat-h2, .mat-typography .mat-headline-6, .mat-typography h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    font-family: Bahnschrift;
    letter-spacing: .0125em;
    margin: 0 0 16px
}

.mat-h3, .mat-subtitle-1, .mat-typography .mat-h3, .mat-typography .mat-subtitle-1, .mat-typography h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    font-family: Bahnschrift;
    letter-spacing: .009375em;
    margin: 0 0 16px
}

.mat-h4, .mat-body-1, .mat-typography .mat-h4, .mat-typography .mat-body-1, .mat-typography h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: Bahnschrift;
    letter-spacing: .03125em;
    margin: 0 0 16px
}

.mat-h5, .mat-typography .mat-h5, .mat-typography h5 {
    font: 400 calc(14px * 0.83)/20px Bahnschrift;
    margin: 0 0 12px
}

.mat-h6, .mat-typography .mat-h6, .mat-typography h6 {
    font: 400 calc(14px * 0.67)/20px Bahnschrift;
    margin: 0 0 12px
}

.mat-body-strong, .mat-subtitle-2, .mat-typography .mat-body-strong, .mat-typography .mat-subtitle-2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    font-family: Bahnschrift;
    letter-spacing: .0071428571em
}

.mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: Bahnschrift;
    letter-spacing: .0178571429em
}

    .mat-body p, .mat-body-2 p, .mat-typography .mat-body p, .mat-typography .mat-body-2 p, .mat-typography p {
        margin: 0 0 12px
    }

    .mat-small, .mat-caption, .mat-typography .mat-small, .mat-typography .mat-caption {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        font-family: Bahnschrift;
        letter-spacing: .0333333333em
    }

    .mat-headline-1, .mat-typography .mat-headline-1 {
        font-size: 96px;
        font-weight: 300;
        line-height: 96px;
        font-family: Bahnschrift;
        letter-spacing: -0.015625em;
        margin: 0 0 56px
    }

    .mat-headline-2, .mat-typography .mat-headline-2 {
        font-size: 60px;
        font-weight: 300;
        line-height: 60px;
        font-family: Bahnschrift;
        letter-spacing: -.0083333333em;
        margin: 0 0 64px
    }

    .mat-headline-3, .mat-typography .mat-headline-3 {
        font-size: 48px;
        font-weight: 400;
        line-height: 50px;
        font-family: Bahnschrift;
        letter-spacing: normal;
        margin: 0 0 64px
    }

    .mat-headline-4, .mat-typography .mat-headline-4 {
        font-size: 34px;
        font-weight: 400;
        line-height: 40px;
        font-family: Bahnschrift;
        letter-spacing: .0073529412em;
        margin: 0 0 64px
    }
